import CssBaseline from "@mui/material/CssBaseline";
import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// includes
import { common, types } from "helpers";
import InnerHeader from "./innerHeader";

const InnerLayout = (props) => {
  // props
  const navigate = useNavigate();
  const pathname = common.getPath(useLocation().pathname);

  // state
  const [isAuth] = useState(common.checkAuth());
  const [open, setOpen] = useState(true);
  const [showSub, setShowSub] = useState(false);
  const [showSubIndex, setShowSubIndex] = useState(null);

  const handleDrawer = () => {
    setOpen(!open);
  };
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!isAuth) {
      localStorage.clear();
      navigate("/login");
    }
  }, [isAuth]);

  const toggleSubMenu = (index) => {
    setShowSubIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // render
  return (
    isAuth && (
      <Fragment>
        <InnerHeader handleDrawer={handleDrawer} />
        <div className="admin-wrap">
          <CssBaseline />
          <aside
            className={!open ? "sidebar-nav-wrap toggle" : "sidebar-nav-wrap"}
          >
            <ul className="sb-nav">
              {console.log("=path", pathname)}
              {types.menus.map(
                (i, k) =>
                  permission?.includes(i.roleKey) && (
                    <>
                      <li
                        key={k}
                        className={
                          pathname === i.menuKey ? "active" : "in-active"
                        }
                      >
                        {i.subtitle1 ? (
                          <div
                            className="main-menu"
                            key={"k_" + k}
                            onClick={() => toggleSubMenu(k)}
                          >
                            <img src={common.loadImg(i.icon)} alt={i.name} />{" "}
                            <span>{i.name}</span>
                          </div>
                        ) : (
                          <Link to={i.url}>
                            <div>
                              <img src={common.loadImg(i.icon)} alt={i.name} />{" "}
                              <span>{i.name}</span>
                            </div>
                          </Link>
                        )}
                      </li>
                      {i.subtitle1 && showSubIndex === k && (
                        <div className="submenu-wrap">
                          {permission?.includes(i.roleKey1) && (
                            <li
                              className={
                                pathname === i.menuKey1
                                  ? "active sub-menu"
                                  : "in-active sub-menu "
                              }
                            >
                              <Link to={i.url}>
                                <div>
                                  <img
                                    src={common.loadImg(i.icon1)}
                                    className="sb-submenu-icn"
                                    alt={i.name}
                                  />
                                  <span>{i.subtitle1} </span>
                                </div>
                              </Link>{" "}
                            </li>
                          )}
                          {permission?.includes(i.roleKey2) && (
                            <li
                              className={
                                pathname === i.menuKey2
                                  ? "active sub-menu"
                                  : "in-active sub-menu "
                              }
                            >
                              <Link to={i.subtitle2 ? i.suburl2 : "/"}>
                                <div>
                                  <img
                                    src={common.loadImg(i.icon2)}
                                    className="sb-submenu-icn"
                                    alt={i.name}
                                  />
                                  <span>{i.subtitle2}</span>
                                </div>
                              </Link>
                            </li>
                          )}
                          {i.subtitle3 && (
                            <>
                              {permission?.includes(i.roleKey3) && (
                                <li
                                  className={
                                    pathname === i.menuKey3
                                      ? "active sub-menu"
                                      : "in-active sub-menu "
                                  }
                                >
                                  <Link to={i.subtitle3 ? i.suburl3 : "/"}>
                                    <div>
                                      <img
                                        src={common.loadImg(i.icon3)}
                                        className="sb-submenu-icn"
                                        alt={i.name}
                                      />
                                      <span>{i.subtitle3}</span>
                                    </div>
                                  </Link>
                                </li>
                              )}
                            </>
                          )}
                          {i.subtitle4 && (
                            <>
                              {permission?.includes(i.roleKey4) && (
                                <li
                                  className={
                                    pathname === i.menuKey4
                                      ? "active sub-menu"
                                      : "in-active sub-menu "
                                  }
                                >
                                  <Link to={i.subtitle4 ? i.suburl4 : "/"}>
                                    <div>
                                      <img
                                        src={common.loadImg(i.icon4)}
                                        className="sb-submenu-icn"
                                        alt={i.name}
                                      />
                                      <span>{i.subtitle4}</span>
                                    </div>
                                  </Link>
                                </li>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )
              )}
            </ul>
          </aside>
          <div
            className={
              !open
                ? "admin-content-wrap pl-toggle"
                : "admin-content-wrap pl-max"
            }
          >
            <Outlet {...props} />
          </div>
        </div>
      </Fragment>
    )
  );
};

export default InnerLayout;
