import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
//mui
// includes
import { common, types } from "helpers";
// pages
import CommonStudentForm from "includes/studentForm";
import CommonStudentView from "includes/studentView";

function Form() {
  const navigate = useNavigate();
  const { id, type, from } = useParams();
  // functions
  const gotoStep = (page) => {
    navigate(`/student/edit/${id}/${page}/${from}`);
  };

  const tooltip = [
    "",
    "General Information",
    "Educational Information",
    "Background Information",
    "Test Course",
    "Work History",
    "Document Attach",
  ];

  return (
    <>
      <Fragment>
        <div className="title-wrap inner-page-header-agent py-0">
          <Row className="row d-flex justify-content-between align-items-center">
            <Col lg={4} md={5} className="application-heading">
              <Link to="/student">
                <img
                  src={common.loadImg("back-icn.svg")}
                  alt=""
                  className="et-cp me-3"
                />
              </Link>
              {"Edit Student"}
            </Col>
            <Col
              lg={4}
              md={4}
              className="d-flex align-items-center justify-content-center dottedclsnew"
            >
              {types.agentProfile.map((i, k) => (
                <div key={k} className="cp" onClick={() => gotoStep(i.type)}>
                  <p className="register-step-head mb-1 me-2">
                    <span
                      className={`dotbtn${i.type !== type ? 1 : ""}`}
                      data-title={tooltip[k+1]}
                    >
                      0{k+1}
                    </span>
                  </p>
                </div>
              ))}
            </Col>
            <Col lg={4} md={3} className="endtext"></Col>
          </Row>
        </div>

        <div className="content-wrap">
          {/* {type !== "document" ? ( */}
            <CommonStudentForm
              studentId={id}
              formModule={type}
              formIndex="all"
              formAddNew={true}
              formModal={false}
              formType="agentNext"
              onSuccess={(id) => {
                common.notify("S", "Profile has been updated");
              }}
            />
          {/* ) : (
            <Fragment>
              <CommonStudentView
                id={id}
                formModule="document"
                onSuccess={() => navigate("/agent/student/list")}
              />
              <div className="register-step-footernew">
                <Link to="/agent/student/list">
                  <button className="btn-default">Done</button>
                </Link>
              </div>
            </Fragment>
          )} */}
        </div>
      </Fragment>
    </>
  );
}

export default Form;
